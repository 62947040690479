import { required, email } from 'vuelidate/lib/validators';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';

export const onlineOrderValidationRules = (onlineOrder: OnlineOrderCoreModel) => {
  return {
    order: {
      email: { required, email },
      phone: { required },
      addressData: {
        name: onlineOrder.requireAddress ? { required } : {},
        street: onlineOrder.requireAddress ? { required } : {},
        building: onlineOrder.requireAddress ? { required } : {},
        locale: {},
        zipCode: onlineOrder.requireAddress ? { required } : {},
        place: onlineOrder.requireAddress ? { required } : {},
      },
      payment: onlineOrder.requirePayment ? { required } : {},
    },
  };
};
